import React from "react";

const PrivacyPolicy = () => {
  const container = {
    padding: 20,
    maxWidth: 1000,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    // height: "100vh",
    // backgroundColor: "#f0f0f0",
    // color: "#333",
  };

  return (
    <div style={container}>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy describes how we collect, use, and protect your personal information
        when you use our mobile application. Protecting your privacy is very important to us, and we
        are committed to safeguarding your personal information.
      </p>

      <h2>1. Information We Collect</h2>
      <p>We may collect the following types of personal information from you:</p>
      <ul>
        <li>Name (optional)</li>
        <li>Email address</li>
        <li>User IDs and passwords</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>The personal information we collect is used for the following purposes:</p>
      <ul>
        <li>To provide and maintain our services</li>
        <li>To offer customer support</li>
        <li>To notify you about changes to our services</li>
        <li>For analytics and statistical purposes</li>
        <li>To comply with legal obligations</li>
      </ul>

      <h2>3. Data Security</h2>
      <p>
        We implement all necessary technical and organizational measures to protect your personal
        data against unauthorized access, alteration, disclosure, or destruction. Your data is
        encrypted and stored on secure servers.
      </p>

      <h2>4. Information Sharing</h2>
      <p>
        We will only share your personal information with third parties under the following
        circumstances:
      </p>
      <ul>
        <li>With your consent</li>
        <li>To comply with legal obligations</li>
        <li>To our service providers and subcontractors who act on our behalf</li>
        <li>As part of business transactions (e.g., mergers, acquisitions)</li>
      </ul>

      <h2>5. Your Rights</h2>
      <p>
        You have the right to access, modify, or delete your personal data. Please contact us if you
        wish to exercise these rights.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to update this Privacy Policy at any time. The latest version will
        always be available in the app. Please review the policy periodically for any updates.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at:</p>
      <p>
        <strong>Owner & Developer Lead</strong> Szabolcs Fuli
        <br />
        <strong>Email</strong> apartseeker.info@gmail.com
        <br />
        <strong>Phone Number</strong> +36309043405
        <br />
        <strong>Address</strong> 9024 Győr, Déry Tibor u. 17.
      </p>
      <p>
        This Privacy Policy is effective immediately and remains in effect until further notice.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
