import React, { useEffect, useState } from "react";
import { content } from "./AppStore";

const Home = () => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch("home.html")
      .then((response) => response.text())
      .then((data) => {
        // setHtmlContent(data);
      });
  }, []);

  return (
    <div id="wrapper">
      <section class="intro">
        <header>
          <h1>ApartSeeker</h1>
          <p>seek'n enjoy</p>
        </header>
        <div class="content">
          <span class="image fill" data-position="center">
            <img src="images/01.jpg" alt="" />
          </span>
        </div>
      </section>

      <section id="first">
        <header>
          <h2>Get it now!</h2>
        </header>
        <div class="content">
          The app is available on both Android and iPhone devices.
          {content({ home: true })}
        </div>
      </section>

      <section>
        <header>
          <h2>Explore</h2>
        </header>
        <div class="content">
          <p>
            Discover Your Perfect Getaway with Our Alternative Accommodation Finder. Welcome to
            ApartSeeker, the ultimate platform for finding the perfect accommodation for your
            vacation. Designed with the user's experience at its core, our app aims to provide the
            best experience with the least amount of user interaction. Our mission is to simplify
            your search for the ideal place to stay, making it as effortless and enjoyable as
            possible.
          </p>
          <span class="image main">
            <img src="images/phone/5-left.png" alt="" />
          </span>
        </div>
      </section>
      <section>
        <header>
          <h2>User Experience</h2>
        </header>
        <div class="content">
          <p>
            Seamless User Experience. At ApartSeeker, we prioritize a seamless user experience. Our
            intuitive features and streamlined design ensure that you can quickly and easily find
            the accommodation that suits your needs. From browsing listings to booking your stay,
            every step is designed to minimize effort and maximize satisfaction.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/6-left.png" alt="" />
            </span>
            <span class="image main">
              <img src="images/phone/22-left.png" alt="" />
            </span>
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Efficient Communication</h2>
        </header>
        <div class="content">
          <p>
            Effective communication between guests and hosts is key to a successful stay. Our app
            facilitates easy and efficient communication, allowing you to connect with hosts
            effortlessly. Whether you have questions about the accommodation or need to make special
            requests, our platform ensures smooth and prompt interactions.
          </p>
          <p>
            Fast and Free Listing. Listing your property on ApartSeeker is quick and hassle-free.
            Hosts can post their listings for free, making it easy to share their unique
            accommodations with a wide audience. We believe in providing value without the burden of
            fees, ensuring that both guests and hosts can benefit from our platform.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/14-left.png" alt="" />
            </span>
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Ad-Free Experience</h2>
        </header>
        <div class="content">
          <p>
            Our app is completely ad-free, allowing you to focus on finding the perfect
            accommodation without distractions. We value your time and attention, and our ad-free
            environment reflects that commitment.
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Easy Login</h2>
        </header>
        <div class="content">
          <p>
            Easy Facebook Login. Accessing our app is as simple as it gets, thanks to our easy
            Facebook login. With just a few taps, you can log in and start exploring the best
            accommodations for your next trip. No complicated sign-up process – just quick and easy
            access to great stays.
          </p>

          <p>
            <span class="image main">
              <img src="images/phone/3-left.png" alt="" />
            </span>
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Advertisers Easily Manage and Update Listings</h2>
        </header>
        <div class="content">
          <p>
            At Apartseeker, we make it simple for advertisers to manage their property listings. Our
            platform offers a range of user-friendly features to ensure your listings are always
            up-to-date and appealing to potential renters.
          </p>

          <h3>Easy Listing Management</h3>
          <p>
            Advertisers can easily add and modify their property listings. Whether you need to
            update the description, change the availability dates, or make other adjustments, our
            intuitive interface makes the process quick and hassle-free.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/8-left.png" alt="" />
            </span>
          </p>

          <h3>Upload Images</h3>
          <p>
            Enhance your listings by uploading high-quality images. Show off the best features of
            your property with multiple photos, giving potential renters a clear and attractive view
            of what you have to offer.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/10-left.png" alt="" />
            </span>
          </p>

          <h3>Progressive Pricing</h3>
          <p>
            Set your prices in a progressive manner to meet market demands. Our flexible pricing
            tools allow you to adjust rates based on seasonality, occupancy rates, and other market
            factors, ensuring you stay competitive and maximize your rental income.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/11-left.png" alt="" />
            </span>
          </p>

          <p>
            Join Apartseeker today and take advantage of our comprehensive tools to efficiently
            manage your property listings.
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Scrolling Experience</h2>
        </header>
        <div class="content">
          <p>
            Our app offers a unique scrolling experience, enhancing the way you browse through
            listings. Images are presented in a captivating slideshow format, allowing you to
            visualize your potential stay vividly. This engaging feature sets ApartSeeker apart,
            making your search both enjoyable and effective.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/21-portrait.png" alt="" />
            </span>
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Completely Free</h2>
        </header>
        <div class="content">
          <p>
            ApartSeeker is completely free to use. We believe that finding and listing
            accommodations should not come with a price tag. Our goal is to create a platform that
            benefits everyone without any hidden costs or fees. Experience the difference with
            ApartSeeker, where simplicity meets excellence in accommodation finding. Start your
            journey today and discover the perfect place for your next vacation.
          </p>
          <p>
            <span class="image main">
              <img src="images/phone/23-left.png" alt="" />
            </span>
          </p>
        </div>
      </section>

      <section>
        <header>
          <h2>Get in Touch</h2>
        </header>
        <div class="content">
          <p>
            <strong>Owner & Developer Lead:</strong> Szabolcs Fuli
            <br />
            <strong>Email:</strong> apartseeker.info@gmail.com
            <br />
            <strong>Phone Number:</strong> +36309043405
            <br />
            <strong>Address:</strong> Hungary, 9024 Győr, Déry Tibor u. 17.
          </p>
        </div>
      </section>
    </div>
  );

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default Home;
