import React from "react";

const TermsOfService = () => {
  const container = {
    padding: 20,
    maxWidth: 1000,
  };

  return (
    <div style={container}>
      <h1>Terms of Service</h1>

      <p>
        Welcome to our application. By accessing or using our service, you agree to be bound by the
        terms and conditions outlined below. If you do not agree with these terms, please do not use
        our service.
      </p>

      <h2>1. Use of Service</h2>
      <p>
        You agree to use the service at your own risk. The content provided through our service is
        for general information purposes only and should not be relied upon for any specific
        purpose.
      </p>

      <h2>2. No Warranty</h2>
      <p>
        We make no representations or warranties of any kind, express or implied, about the
        completeness, accuracy, reliability, suitability, or availability of the information
        provided on our service. Any reliance you place on such information is therefore strictly at
        your own risk.
      </p>

      <h2>3. Limitation of Liability</h2>
      <p>
        In no event will we be liable for any loss or damage including without limitation, indirect
        or consequential loss or damage, or any loss or damage whatsoever arising from the use of
        our service.
      </p>

      <h2>4. User Responsibilities</h2>
      <p>
        As a user, you are responsible for ensuring that your use of the service complies with all
        applicable laws and regulations. You agree not to use the service for any unlawful or
        prohibited activities.
      </p>

      <h2>5. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these terms at any time. Any changes will be effective
        immediately upon posting on our service. Your continued use of the service after any
        modifications indicates your acceptance of the new terms.
      </p>

      <h2>6. Governing Law</h2>
      <p>
        These terms are governed by and construed in accordance with the laws of Hungary. Any
        disputes arising from or related to the use of our service will be subject to the exclusive
        jurisdiction of the courts of Hungary.
      </p>

      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms of Service, please contact us at:</p>

      <p>
        <strong>Owner & Developer Lead</strong> Szabolcs Fuli
        <br />
        <strong>Email</strong> apartseeker.info@gmail.com
        <br />
        <strong>Phone Number</strong> +36309043405
        <br />
        <strong>Address</strong> 9024 Győr, Déry Tibor u. 17.
      </p>
    </div>
  );
};

export default TermsOfService;
