import React from "react";

const AppStore = () => {
  const container = {
    padding: 20,
    maxWidth: 1000,
  };

  return (
    <div style={{ ...container, ...{ background: "linear-gradient(#ffffff, #a2f7ff)" } }}>
      {content({ home: false })}
    </div>
  );
};

export const content = ({ home }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-around",
    padding: "20px",
  };

  const appContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const imageStyle = {
    marginTop: "10px",
  };
  return (
    <p style={{ textAlign: "center" }}>
      {!home && (
        <>
          <h1 style={{ textTransform: "none", fontSize: "3rem" }}>AppartSeeker</h1>
          <div>
            <img src="images/logo003.png" width={200} style={{ borderRadius: 30 }} alt="" />
          </div>
          <div>The ultimate platform for finding the perfect accommodation for your vacation.</div>
          <h3>seek'n enjoy</h3>
        </>
      )}

      <div style={containerStyle}>
        <div style={appContainerStyle}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.venomtools.apartseeker&pcampaignid=web_share"
          >
            <img height={40} src="images/google-en.png" alt="ApartSeeker on Google Play" />
            <div>
              <img
                src="images/qr-android.png"
                alt="QR Code for Android"
                style={imageStyle}
                width="150"
              />
            </div>
          </a>
        </div>

        <div style={appContainerStyle}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/apartseeker/id6480477712?platform=iphone"
          >
            <img
              height={40}
              src="images/download-on-the-app-store.svg"
              alt="ApartSeeker on App Store"
            />
            <div>
              <img
                src="images/qr-apple.png"
                alt="QR Code for Apple"
                style={imageStyle}
                width="150"
              />
            </div>
          </a>
        </div>
      </div>
    </p>
  );
};

export default AppStore;
