import React from "react";

const UserAccountDeletion = () => {
  const container = {
    padding: 20,
    maxWidth: 1000,
  };

  return (
    <div style={container}>
      <h1>User Account Deletion</h1>
      <h2>How to Delete Your User Account</h2>
      <p>If you wish to delete your user account, you can do so in two ways:</p>
      <ol>
        <li>
          <strong>Through the App:</strong>
          <ol>
            <li>Log in to the Apartseeker app.</li>
            <li>Click on the icon in the top right corner to open the profile menu.</li>
            <li>Select the "Delete My Account" option.</li>
            <li>By pressing the <strong>delete</strong> option, your data will be deleted.</li>
          </ol>
        </li>
        <li>
          <strong>By Email:</strong>
          <ol>
            <li>Send us an email to <strong>apartseeker.info@gmail.com</strong> with your deletion request. A short sentence is sufficient.</li>
            <li class="important">
              Important: Make sure to send the request from the email address associated with your
              Facebook profile.
            </li>
            <li class="important">
              We will delete your user account.
            </li>
          </ol>
        </li>
      </ol>

      <p>If you have any questions, please contact us at:</p>
      <p>
        <strong>Owner & Developer Lead</strong> Szabolcs Fuli
        <br />
        <strong>Email</strong> apartseeker.info@gmail.com
        <br />
        <strong>Phone Number</strong> +36309043405
        <br />
        <strong>Address</strong> 9024 Győr, Déry Tibor u. 17.
      </p>
    </div>
  );
};

export default UserAccountDeletion;
